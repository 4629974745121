import { types, destroy, getParent, SnapshotOut, cast, decorate, Instance, UnionStringArray, ISimpleType, IModelType, ModelProperties } from 'mobx-state-tree';
import moment, { Moment, MomentBuiltinFormat, isMoment } from 'moment';
import { coordinateFilters } from './Middleware';
import { LateStoreModel } from './DataStore';
import { getThrones, GOT } from './enums';
// import { pluck, giveIndices } from '../utils/utils';
// import { getView, BricksView } from './enums';

function __iteratorFn(init, cond, tick, tx) {
    let __state = { ...init };
    return Object.create({}, {
        __state: { get() { return __state; }, set(val) { __state = { ...val }; } },
        next: {
            value: function () {
                if (cond(this.__state)) {
                    const value = tx(this.__state);
                    this.__state = tick(this.__state);
                    return { done: false, value };
                }
                else {
                    return { done: true, value: tx(this.__state) };
                }
            }
        }
    });
}

export const DateType = types.custom<string & MomentBuiltinFormat, Moment>({
    name: "ISOStringToMoment",
    fromSnapshot(value: string & MomentBuiltinFormat): Moment {
        return moment(value);
    },
    toSnapshot(value: Moment): string & MomentBuiltinFormat {
        return value.toISOString() as string & MomentBuiltinFormat;
    },
    isTargetType(value: string & MomentBuiltinFormat | Moment): boolean {
        return isMoment(value);
    },
    getValidationMessage(value: string & MomentBuiltinFormat): string {
        try {
            const checker = /(?:\.[0-9]+Z)?|(?:\.[0-9]+)?\+/;
            if (moment(value).toISOString().split(checker)[0] === <string>value.split(checker)[0]) return "";// OK
            return `'${value}' isn't a proper ISOString`;
            // return "";
        }
        catch (err) {
            return `'${value}' isn't a proper ISOString`;
        }
    }
});

export function UniqueArray<T extends ISimpleType<UnionStringArray<string[]>>>(name: string, SubType: T) {
    const __fancyName = `${name[0].toUpperCase()}${name.slice(1)}`;
    return types.model({
        [name]: types.array(SubType),
    }).views(self => ({
        [`has${__fancyName}`](val: string) {
            return SubType.is(val) && self[name].includes(val);
        }
    })).actions(self => ({
        [`push${__fancyName}`](vals: string[]) {

            const safeVals = vals.filter(val => SubType.is(val) && !self[`has${__fancyName}`](val));
            self[name].push(...safeVals);
        },
        [`pop${__fancyName}`](val: string) {

            if (SubType.is(val) && self[`has${name[0].toUpperCase()}${name.slice(1)}`](val)) {
                self[name].remove(val);
            }
        }
    }));
}

interface IEnumArgs {
    name: string;
    vals: string[];
    defaultArg?: string;
}

function safeEnum({ name, vals, defaultArg: leDefault }: IEnumArgs) {
    const defaultArg = leDefault && vals.includes(leDefault) ? leDefault : vals[0];
    const valsI = vals.map(str => str.toLowerCase());
    const valsIter = Object.create({}, { [Symbol.iterator]: { value: () => __iteratorFn({ count: 0 }, st => st.count < vals.length, st => ({ count: st.count + 1 }), st => ([valsI[st.count], vals[st.count]])) } });
    const enumVals = types.enumeration(`${name}s`, vals);
    const enumValsI = types.enumeration(`case insensitive ${name}s`, valsI);
    const connection = new Map(valsIter);
    return {
        enumeration: enumVals,
        model: types.model(name, { [name]: enumVals })
            .preProcessSnapshot(sn => ({
                ...sn,
                [name]: (sn[name] && enumValsI.is(sn[name].toLowerCase())) ? connection.get(sn[name].toLowerCase()) as string : defaultArg
            }))
    };
}

// Enum types and other composable types
export const [{ enumeration: eStatus, model: Status }, { enumeration: eScreen, model: Screen }, { enumeration: eGraphType, model: GraphType }, { enumeration: ePhase, model: Phase }, { enumeration: eSection, model: Section }] = [
    { name: 'status', vals: ['completed', 'started', 'notStarted', 'plainWrong'], defaultArg: 'plainWrong' },
    { name: 'screen', vals: ['fullscreen', 'landing', 'misc', 'powerBI', 'tasks-assigned', 'tasks-initiated'], defaultArg: 'landing' },
    { name: 'graphType', vals: ['brickGraph', 'facade', 'table', 'barChart', 'powerbi', 'baratheon'], defaultArg: 'brickGraph' },
    { name: 'phase', vals: ['structures', 'finishing', 'handover', 'none', 'all'], defaultArg: 'none' },
    { name: 'section', vals: ['dashboard', 'unit-act-info', 'snags', 'tasks', 'powerbi', 'home', 'legacy', 'projects', 'checklist-reports', 'form-data', 'form-data-write', 'requests', 'admin-tools', 'planning', 'fbt-reports', 'dpr', 'dpr-report', 'snag-import', 'js-report', 'activity-manager'], defaultArg: 'home' },
].map((arg: IEnumArgs) => (safeEnum(arg)));

const defaultSpaceTypeNames = {
    tower: "Towers",
    tca: "Tower Common Area",
    eca: "External Common Area",
    basement: "Basement"
};

export const SpaceType = types.model({
    spaceType: types.optional(types.string, "none"),
    spaceTypes: types.array(types.model({ id: types.string, name: types.string }))
})
    .volatile<{ loading: boolean; }>(_ => ({ loading: false }))
    .views(self => ({
        get SpaceTypeName() {
            let i = 0;
            for (; i < self.spaceTypes.length; i++) {
                if (self.spaceTypes[i].id === self.spaceType) {
                    const name = self.spaceTypes[i].name;
                    return name && name.length ? name : defaultSpaceTypeNames[self.spaceTypes[i].id] || "";
                }
            }
            return null;
        },
        getSpaceTypeName(val: string) {
            // console.log(self.spaceTypes);
            let i = 0;
            for (; i < self.spaceTypes.length; i++) {
                if (self.spaceTypes[i].id === val) {
                    const name = self.spaceTypes[i].name;
                    return name && name.length ? name : defaultSpaceTypeNames[self.spaceTypes[i].id] || "";
                }
            }
            return null;
        }
    }))
    .actions(self => ({
        setSpaceType(val: string) {
            self.spaceType = val;
        },
        setSpaceTypes(val: { id: string; name: string; }[]) {
            self.spaceTypes.clear();
            self.spaceTypes.push(...val);
        }
    }));

export const Tab = types.model({
    tab: types.optional(types.string, "none"),
    tabs: types.array(types.model("tabs", { id: types.identifier, name: types.string }))
})
    .volatile(_ => ({ loading: false }))
    .views(self => ({
        get tabName() {
            let i = 0, len = self.tabs.length;
            for (; i < len; i++) {
                if (self.tabs[i].id === self.tab) {
                    return self.tabs[i]!.name;
                }
            }
            return null;
        }
    }))
    .actions(self => ({
        setTab(val: string) {
            self.tab = val;
        },
        setTabs(val: { id: string; name: string; }[]) {
            self.tabs.clear();
            self.tabs.push(...val);
        }
    }));


// Can't compose because apparently limit is 9 and this is the 10th one.
export const UnitType = types.model("unit type", {
    unitType: types.maybeNull(types.string)
}).actions(self => ({
    setUnitType(val?: string) {
        self.unitType = val || null;
    }
}));

export const PTower = types.model({ tower: types.maybeNull(types.string) });
export const PUnit = types.model({ unit: types.maybeNull(types.string) });
export const PActivity = types.model({ activity: types.maybeNull(types.string) });
export const Phases = types.model({ phasesWithNames: types.array(types.frozen<{ id: string; name: string; }>()) })
    .views(self => ({
        get phases() {
            return self.phasesWithNames.map(({ id }) => id);
        },
        getPhaseName(phase: string): string {
            // TODO: temporary label fix. Generalize to config later.
            const name = self.phasesWithNames.find(({ id }) => id === phase)?.name;
            return name && name.length ? name : phase || "Others";
        }
    }))
    .actions(self => ({
        setPhases(val: any[]) {
            self.phasesWithNames.clear();
            self.phasesWithNames.push(...val);
        }
    }));

const WorkaroundParams = types.compose(Phases, UnitType, Tab);
export const Params = types.compose(Screen, GraphType, Phase, PTower, PActivity, PUnit, Section, SpaceType, WorkaroundParams)
    .views(self => ({
        get path() {
            let constructedPath = "/";
            if (self.section !== 'home' && self.section !== 'legacy') { constructedPath += self.section + '/'; }
            switch (self.section) {
                case 'planning':
                case 'requests':
                case 'home':
                case 'powerbi': break;
                case 'snags':
                case 'tasks':
                case 'checklist-reports':
                case 'unit-act-info': if (self.screen === 'landing' || self.screen === 'fullscreen') {
                    if (self.phase) { constructedPath += self.phase + '/'; }
                    if (self.spaceType.toLowerCase() !== "none") { constructedPath += self.spaceType + '/'; }
                }
                    if (self.screen === 'fullscreen' && self.tower) { constructedPath += self.tower + '/'; };
                    break;
                case 'dashboard': if (self.phase === 'none' || self.spaceType === 'none') { throw new Error("invalid path"); }
                    constructedPath += self.phase + '/';
                    constructedPath += self.spaceType + '/';
                    if (self.screen === 'landing' || self.screen === 'fullscreen') { constructedPath += self.screen + '/'; }
                    if ((self.spaceType === 'tca' || self.spaceType === 'eca' || self.spaceType === 'basement') && self.tab) {
                        constructedPath += self.tab + '/';
                    }
                    if (self.screen === 'fullscreen') {
                        if (self.tower) {
                            constructedPath += self.tower + '/';
                            if (self.spaceType === 'tca' || self.spaceType === 'eca' || self.spaceType === 'basement') {
                                if (self.unitType) {
                                    constructedPath += self.unitType + '/';
                                }
                                if (self.graphType === 'baratheon') {
                                    constructedPath += '?graphType=' + self.graphType;
                                }
                            }
                            else if (self.graphType) { constructedPath += self.graphType + '/'; }
                        }
                        else { constructedPath = constructedPath.slice(0, constructedPath.length - 1); constructedPath += '?graphType=' + (self.graphType || 'brickGraph'); }
                    };
                    break;
                case 'legacy': if (self.screen === 'fullscreen') {
                    constructedPath += 'unitInfo/';
                    constructedPath += self.phase + '/';
                    if (self.tower) {
                        constructedPath += self.tower + '/';
                    }
                }
                    break;
                default: constructedPath = '/';
            }
            return constructedPath;
        },
        toPath(sn: SnapshotOut<typeof Params>) {
            const { section, screen, spaceType, phase, graphType, tower, tab, unitType } = sn;
            let constructedPath = "/";
            if (section !== 'home' && section !== 'legacy') { constructedPath += section + '/'; }
            switch (section) {
                case 'planning':
                case 'requests':
                case 'home':
                case 'powerbi': break;
                case 'snags':
                case 'tasks':
                case 'checklist-reports':
                case 'unit-act-info': if (screen === 'landing' || screen === 'fullscreen') {
                    if (phase) { constructedPath += phase + '/'; }
                    if (spaceType.toLowerCase() !== "none") { constructedPath += spaceType + '/'; }
                }
                    if (screen === 'fullscreen' && tower) { constructedPath += tower + '/'; };
                    break;
                case 'dashboard': if (phase === 'none' || spaceType === 'none') { throw new Error("invalid path"); }
                    constructedPath += phase + '/';
                    constructedPath += spaceType + '/';
                    if (screen === 'landing' || screen === 'fullscreen') { constructedPath += screen + '/'; }
                    if ((spaceType === 'tca' || spaceType === 'eca' || spaceType === "basement") && tab) {
                        constructedPath += tab + '/';
                    }
                    if (screen === 'fullscreen') {
                        if (tower) {
                            constructedPath += tower + '/';
                            if (spaceType === 'tca' || spaceType === 'eca' || spaceType === "basement") {
                                if (unitType) {
                                    constructedPath += unitType + '/';
                                }
                            }
                            else if (graphType) { constructedPath += graphType + '/'; }
                        }
                        else if (spaceType === 'tower') { constructedPath = constructedPath.slice(0, constructedPath.length - 1); constructedPath += '?graphType=' + (graphType || 'brickGraph'); }
                    };

                    if ((spaceType === 'tca' || spaceType === 'eca' || spaceType === "basement") && getThrones(graphType) === GOT.BARATHEON && (screen === 'landing' || (screen === 'fullscreen' && unitType))) {
                        constructedPath += graphType + '/';
                    }
                    break;
                case 'legacy': if (screen === 'fullscreen') {
                    constructedPath += 'unitInfo/';
                    constructedPath += phase + '/';
                    if (tower) {
                        constructedPath += tower + '/';
                    }
                }
                    break;
                default: constructedPath = '/';
            }
            return constructedPath;
        }
    }))
    .actions(self => ({
        setParams(sn: SnapshotOut<typeof self>) {
            (getParent(self) as Instance<typeof LateStoreModel>).setParams(cast(sn));
        },
        setParam(param: 'screen' | 'graphType' | 'phase' | 'tower' | 'activity' | 'unit' | 'spaceType' | 'section' | 'tab' | 'unitType', val: string) {
            switch (param) {
                case 'screen': self.screen = val; break;
                case 'phase': self.phase = val; break;
                case 'graphType': self.graphType = val; break;
                case 'tower': self.tower = val; break;
                case 'activity': self.activity = val; break;
                case 'unit': self.unit = val; break;
                case 'section': self.section = val; break;
                case 'spaceType': self.setSpaceType(val); break;
                case 'tab': self.setTab(val); break;
                case 'unitType': self.setUnitType(val); break;
                default: break;
            };
        }
    }));

export interface IDateDiffC {
    from?: string & MomentBuiltinFormat;
    to?: string & MomentBuiltinFormat;
};

export const DateDiff = types.model({
    from: types.maybeNull(DateType),
    to: types.maybeNull(DateType)
}).views(self => {
    const today = moment().startOf('day');
    return {
        get diff() { if (self.from && self.to) return self.to.diff(self.from, 'days'); },
        get diffFrom() { if (self.from) return today.diff(self.from, 'days'); },
        get diffTo() { if (self.to) return today.diff(self.to, 'days'); }
    };
}).actions(self => (
    {
        afterCreate() {
            if (!self.from && !self.to)
                throw new Error("Can't leave both parts of date range blank");
        },
        setFrom(val: string & MomentBuiltinFormat) { self.from = moment(val); },
        setTo(val: string & MomentBuiltinFormat) { self.to = moment(val); }
    }))

export const IdDateDiff = types.model({
    id: types.identifier,
    delta: types.maybeNull(DateDiff)
}).preProcessSnapshot(sn => ({
    ...sn,
    id: 'filterDelta'
})).views(self => ({
    get diff() { if (self.delta) return self.delta.diff; },
    get diffFrom() { if (self.delta) return self.delta.diffFrom; },
    get diffTo() { if (self.delta) return self.delta.diffTo; }
}));

// const noOfDigits = (num, i = 1) => (num / 10 < 1 ? i : noOfDigits(num / 10, i + 1));
// activities: {done: 415, total: 1000}
// units: {done: 14, total: 35}
export const { enumeration: eProgressTypes, model: ProgressTypes } = safeEnum({ name: 'progressType', vals: ['activities', 'units', 'days'], defaultArg: 'units' });
const ProgressTypesWithDetails = types.enumeration('progressType', ['activities', 'units']);
export const ProgressMeasure = types.model({
    progressType: ProgressTypesWithDetails,
    done: types.refinement(types.number, Number.isInteger),
    total: types.refinement(types.number, Number.isInteger),
}).views(self => ({
    get display() {
        const suffix = self.progressType[0].toUpperCase();
        const digits = 2;
        return `${self.done.toLocaleString('en', { minimumIntegerDigits: digits }).replace(',', '')} / ${self.total.toLocaleString('en', { minimumIntegerDigits: digits }).replace(',', '')}${suffix} `;
    }
}));

export const Measure = types.model({}).views((self: Instance<IModelType<ModelProperties & { total: ISimpleType<number>; completed: ISimpleType<number>; }, any, any, any>>) => ({
})).views((self: Instance<IModelType<ModelProperties & { total: ISimpleType<number>; completed: ISimpleType<number>; }, any, any, any>>) => ({
    get digits() {
        // return noOfDigits(self.total);
        return 2;
    },
    get progressDisplay() {
        // const digits = Math.min(noOfDigits(self.total), 2);
        const digits = 2;
        return `${self.completed.toLocaleString('en', { minimumIntegerDigits: digits }).replace(',', '')} / ${self.total.toLocaleString('en', { minimumIntegerDigits: digits }).replace(',', '')}`;
    }
}));

// More complex types
export const Filters = types.compose(
    types.model({ 'tower': types.array(types.string) }),
    UniqueArray('status', eStatus),
    types.model({ progressType: types.optional(types.enumeration('vals', ['activities', 'units']), 'units') }),
    types.model({
        delta: IdDateDiff,
        delayed: types.optional(types.boolean, false),
        open: types.optional(types.boolean, false),
        infopanelOpen: types.optional(types.boolean, false),
        calenderOpen: types.maybeNull(types.string),
        floorSort: types.optional(types.boolean, false),
        activity: types.maybeNull(types.string),
        graphType: types.maybeNull(types.string)
    }))
    .views(self => ({
        get activeFilters() {
            const andFilter = [self.status.length > 0 && 'status', self.delta.delta && 'delta', self.delayed && 'delayed', self.open && 'open', self.calenderOpen && 'calenderOpen', self.infopanelOpen && 'infopanelOpen'];
            return andFilter.filter(a => a);
        }
    }))
    .views(self => ({
        hasTower: (value: string) => self.tower.includes(value)
    }))
    .actions(self => {
        function setTower(vals: string[]) {
            self.tower.clear();
            self.tower.push(...vals);
        }
        function setStatus(vals: string[]) {
        }
        function setDelayed(arg = false) {
            self.delayed = arg;
        }
        function setOpen(arg = false) {
            self.open = arg;
        }
        function setGraphType(arg?: string) {
            if (!arg) { self.graphType = null; }
            else { self.graphType = arg; }
        }
        function setInfopanelOpen(arg = false) {
            self.infopanelOpen = arg;
        }
        function setCalenderOpen(arg?: string) {
            if (!arg) { self.calenderOpen = null; }
            else { self.calenderOpen = arg; }
        }
        function setDelta(sn: IDateDiffC) {
            if (!self.delta.delta)
                self.delta.delta = DateDiff.create({ ...sn });
            else {
                if (!!sn.from) { self.delta.delta.setFrom(sn.from); }
                if (!!sn.to) { self.delta.delta.setTo(sn.to); }
            }
        }
        function destroyDelta() {
            destroy(cast(self.delta.delta));
        }
        function setActivity(val?: string) {
            if (!val) { self.activity = null; }
            else {
                self.activity = val;
            }
        }
        return {
            setStatus: decorate(coordinateFilters, setStatus),
            setTower: setTower,
            setDelayed: setDelayed,
            setOpen: setOpen,
            setInfopanelOpen: setInfopanelOpen,
            setCalenderOpen: setCalenderOpen,
            setActivity,
            setDelta,
            setGraphType,
            destroyDelta: decorate(coordinateFilters, destroyDelta),
            setFloorSort(arg: boolean = false) {
                self.floorSort = arg;
            },
            setProgressType(display: 'activities' | 'units' = 'units') {
                if (!(self.progressType && self.progressType === display))
                    self.progressType = display;
            }
        };
    })
    .actions(self => ({
        clearFilters() { self.setTower([]); self.setStatus([]); self.setOpen(); self.setCalenderOpen(); self.setInfopanelOpen(); self.setDelayed(); self.setActivity(); if (self.delta.delta) { self.destroyDelta(); } self.setProgressType(); self.setFloorSort(); self.setGraphType(); },
        patchFilters(sn: Record<string, string | string[]>) {
            if (Object.keys(sn).length === 0) {
                this.clearFilters();
            }
            else {
                let delta = {};
                const toClear = ['tower', 'activity', 'status', 'delayed', 'open', 'calenderOpen', 'display', 'delta', 'graphType'].reduce((acc: string[], key: string) => {
                    if (Array.isArray(key) && !(sn.to || sn.from) && self.delta.delta)
                        return [...acc, 'delta'];
                    switch (key) {
                        case 'delta': if (!(sn.to || sn.from) && self.delta.delta)
                            return [...acc, 'delta'];
                            break;
                        case 'delayed': if (!sn[key] && self.delayed)
                            return [...acc, key];
                            break;
                        case 'open': if (!sn[key] && self.open)
                            return [...acc, key];
                            break;
                        case 'infopanelOpen': if (!sn[key] && self.infopanelOpen)
                            return [...acc, key];
                            break;
                        case 'calenderOpen': if (!sn.calenderOpen && self[key]) {
                            return [...acc, 'calenderOpen'];
                        }
                            break;
                        case 'display': if (!sn[key] && self[key])
                            return [...acc, key];
                            break;
                        case 'sort': if (sn[key] === undefined && self[key])
                            return [...acc, key];
                            break;
                        case 'status': if (!sn[key] && self[key].length)
                            return [...acc, key]
                            break;
                        case 'tower':
                            if (!sn[key] && self[key] && self[key].length && self[key].length > 0)
                                return [...acc, key];
                            break;
                        case 'activity': if (!sn.activity && self[key]) { return [...acc, 'activity']; } break;
                        case 'graphType': if (!sn.graphType && self[key]) { return [...acc, 'graphType']; } break;
                        default:
                    }
                    return acc;
                }, []);
                (toClear as string[]).forEach(clear => {
                    switch (clear) {
                        case 'tower': self.setTower([]); break;
                        case 'status': self.setStatus([]); break;
                        case 'delayed': self.setDelayed(); break;
                        case 'open': self.setOpen(); break;
                        case 'infopanelOpen': self.setInfopanelOpen(); break;
                        case 'calenderOpen': self.setCalenderOpen(); break;
                        case 'delta': self.destroyDelta(); break;
                        case 'display': self.setProgressType(); break;
                        case 'sort': self.setFloorSort(); break;
                        case 'activity': self.setActivity(); break;
                        case 'graphType': self.setGraphType(); break;
                        default: break;
                    }
                });
                Object.keys(sn).forEach(k => {
                    switch (k) {
                        case 'tower': self.setTower(sn[k] ? sn[k] as string[] : []);
                            break;
                        case 'status': self.setStatus(sn[k] as string[]);
                            break;
                        case 'delayed': const boolDelayed = sn.delayed === "true" ? true : false;
                            if (self.delayed !== boolDelayed) { self.setDelayed(boolDelayed as boolean); }
                            break;
                        case 'open': const boolOpen = sn.open === "true" ? true : false;
                            if (self.open !== boolOpen) { self.setOpen(boolOpen as boolean); }
                            break;
                        case 'infopanelOpen': const boolInfopanelOpen = sn.infopanelOpen === "true" ? true : false;
                            if (self.infopanelOpen !== boolInfopanelOpen) { self.setInfopanelOpen(boolInfopanelOpen as boolean); }
                            break;
                        case 'calenderOpen': if (self.calenderOpen !== sn.calenderOpen) { self.setCalenderOpen(sn.calenderOpen as string); }
                            break;
                        case 'display': self.setProgressType(sn[k] as 'activities' | 'units');
                            break;
                        case 'from': if (!!sn.from && typeof sn.from === 'string') {
                            delta['from'] = moment(sn.from).toISOString() as string & MomentBuiltinFormat;
                        }; break;
                        case 'to': if (!!sn.to && typeof sn.to === 'string') {
                            delta['to'] = moment(sn.to).toISOString() as string & MomentBuiltinFormat;
                        }; break;
                        case 'sort': if (`${self.floorSort}` !== sn.sort) {
                            self.setFloorSort(sn.sort === "true");
                        }
                            break;
                        case 'activity': if (self.activity !== sn.activity) {
                            self.setActivity(sn.activity as string);
                        }
                            break;
                        case 'graphType': if (self.graphType !== sn.graphType) { self.setGraphType(sn.graphType as string); }
                            break;
                        default:
                            break;
                    }
                });
                if (Object.keys(delta).length > 0) {
                    self.setDelta({ ...delta });
                }
            }
        }
    }));


/*
  Model created for activity filtering
  ----*-------*-------*-------*-------*-------*-------*-------*-------*-------*-------*-------*----
  Currently hardcoded for columns
  ,----
  |
  | Assuming that the array to be filtered is sorted, this model creates
  | in a volatile state, an array buffer with Math.ceil(length/8) space to store a bitmask
  | representation of the filter indices. Then, it cheaply returns the filtered,
  | and sorted array.
  |
  `----
*/

// export const BulkFilter = types.model<{}>("BulkFilter", {
// }).views<{ readonly filterRef: SnapshotOrInstance<typeof Filters>; }>(self => ({
//     get filterRef(): SnapshotOrInstance<typeof Filters> {
//         return (getRoot(self) as Instance<typeof LateStoreModel>).filters
//     }
// })).views(self => ({
//     get columnsFiltered(): Instance<typeof CellAddressUnitXLate>[] {
//         if (![BricksView.CONVENTIONALF, BricksView.FINLP].includes(getView((getRoot(self) as Instance<typeof LateStoreModel>).params.phase, (getRoot(self) as Instance<typeof LateStoreModel>).params.screen))) {
//             return self['columnSorted'];
//         }
//         const arrayBuffer = new ArrayBuffer(Math.ceil((self['columns'] as Instance<IMapType<typeof CellAddressUnitXLate>>)!.size / 8));
//         const len = arrayBuffer.byteLength;
//         const decoded = self.filterRef['activity'] && decodeURIComponent(self.filterRef['activity']);
//         if (!decoded) { return self['columnSorted']; }
//         let view = new Uint8Array(arrayBuffer), loop = 0;
//         for (loop = 0; loop < len; loop++) { view[loop] = decoded.charCodeAt(loop); }
//         const indices = giveIndices.apply(arrayBuffer);
//         return indices.length === 0 ? self['columnSorted'] : pluck.apply(self['columnSorted'], indices);
//     },
//     get indicesCol(): number[] {
//         if (!self.filterRef['activity']) {
//             return [];
//         }
//         if (getView((getRoot(self) as Instance<typeof LateStoreModel>).params.phase, (getRoot(self) as Instance<typeof LateStoreModel>).params.screen) !== self['type']) {
//             return [];
//         }
//         const arrayBuffer = new ArrayBuffer(Math.ceil((self['columns'] as Instance<IMapType<typeof CellAddressUnitXLate>>)!.size / 8));
//         const len = arrayBuffer.byteLength;
//         const decoded = self.filterRef['activity'] && decodeURIComponent(self.filterRef['activity']);
//         if (!decoded) { return []; }
//         let view = new Uint8Array(arrayBuffer), loop = 0;
//         for (; loop < len; loop++) { view[loop] = decoded.charCodeAt(loop); }
//         return giveIndices.apply(arrayBuffer);
//     },
//     get stages(): { stage: string; length: number; index: number; order: number }[] {
//         if (
//             self["type"] === BricksView.FINLP ||
//             self["type"] === BricksView.CONVENTIONALF
//         ) {
//             const interArray: {
//                 stage: string;
//                 index: number;
//                 order: number;
//             }[] = self["columnSorted"].reduce(
//                 (
//                     acc: { stage: string; index: number; order: number }[],
//                     column: Instance<typeof CellAddressUnitXLate>,
//                     ind: number
//                 ) =>
//                     column["stage"] &&
//                         column["stage"] !==
//                         (self["columnSorted"][ind > 0 ? ind - 1 : 0] &&
//                             self["columnSorted"][ind > 0 ? ind - 1 : 0]["stage"])
//                         ? [
//                             ...acc,
//                             {
//                                 stage:
//                                     self["columnSorted"][ind > 0 ? ind - 1 : 0]["stage"],
//                                 index: ind - 1,
//                                 order: acc.length > 0 ? acc[acc.length - 1].order + 1 : 0
//                             }
//                         ]
//                         : ind + 1 === self["columnSorted"].length
//                             ? [
//                                 ...acc,
//                                 {
//                                     stage: column["stage"],
//                                     index: ind + 1,
//                                     order: acc.length > 0 ? acc[acc.length - 1].order + 1 : 0
//                                 }
//                             ]
//                             : acc,
//                 []
//             );
//             return interArray.reduce(

//                 (acc, { stage, index, order }, ind) => [
//                     ...acc,
//                     {
//                         stage,
//                         order,
//                         index: ind === 0 ? 0 : interArray[ind - 1].index,
//                         length: ind === 0 ? index : index - interArray[ind - 1].index
//                     }
//                 ],
//                 []
//             );
//         } else {
//             return [];
//         }
//     }
// }));

export const TowerStructuresProgressDetails = types.model({
    id: types.identifier,
    overall: types.number,
    per_pour: types.number
}).views(self => ({ get perPour() { return self.per_pour; } }));
export const TowerProgressDetails = types.model({
    structures: types.maybeNull(TowerStructuresProgressDetails),
});



//     ,----
//     |
//     | Attempt at making a ProgressDetails model for Finishing. Currently,
//     |  this code lives as its own Bricks array in the tower model.
//     |
//     `----
//
//
// export const TowerFinishingProgressDetails = types.model({
//     id: types.identifier,
//     total: types.frozen(types.refinement(types.number, Number.isInteger)),
//     completed: types.frozen(types.refinement(types.number, Number.isInteger)),
//     inProgress: types.frozen(types.refinement(types.number, Number.isInteger)),
//     actualEnd: types.maybeNull(DateType),
//     planStart: types.maybeNull(DateType),
// }).views(self => ({
//     get status(): SnapshotOut<typeof eStatus> {
//         if (self.completed === 0 && self.inProgress === 0)
//             return 'notStarted';
//         else if (self.total > 0 && self.total === self.completed)
//             return 'completed';
//         else if (self.inProgress > 0 || self.completed > 0)
//             return 'started';
//         else
//             return 'plainWrong';
//     },
//     get phase(): SnapshotOut<typeof ePhase> {
//         return "finishing";
//     },
//     get screen(): SnapshotOut<typeof eScreen> {
//         return (getRoot(self) as Instance<typeof LateStoreModel>).params.screen;
//     }
// })).views(self => ({
//     get display(): string {
//         switch (self.status) {
//             case 'notStarted': return self.planStart ? self.planStart.format(`MMM[-W${self.planStart.isoWeek() - self.planStart.startOf('month').isoWeek()}-']YY`) : '—';
//             case 'started': return `${(self.completed + self.inProgress).toLocaleString('en', { minimumIntegerDigits: 2 })} / ${self.total.toLocaleString('en', { minimumIntegerDigits: 2 })}`;
//             case 'completed': return self.actualEnd ? self.actualEnd.format('DD[/]MM[/]YY') : '—';
//             default: return "!";
//         }
//     },
//     get progressMultiplier(): number {
//         return 1;
//     },
//     get color(): string {
//         return getEnv(self).brickPalette[self.phase][self.screen][self.status];
//     }
// }));

export interface ITowerStructuresProgressDetails extends Instance<typeof TowerStructuresProgressDetails> { };
export interface ITowerProgressDetails extends Instance<typeof TowerProgressDetails> { };
export interface IProgressType extends Instance<typeof ProgressTypes> { };
export interface IProgressMeasure extends Instance<typeof ProgressMeasure> { };
export interface IParams extends Instance<typeof Params> { };
export interface IScreen extends Instance<typeof Screen> { };
export interface IGraphType extends Instance<typeof GraphType> { };
export interface ISpaceType extends Instance<typeof SpaceType> { };
export interface ISection extends Instance<typeof Section> { };
export interface IPhase extends Instance<typeof Phase> { };
export interface IStatus extends Instance<typeof Status> { };
export interface IDateDiff extends Instance<typeof DateDiff> { };
export interface IIdDateDiff extends Instance<typeof IdDateDiff> { };
export type IFilters = Instance<typeof Filters>;

