import React from 'react';
import { DataSheetGrid, Column } from 'react-datasheet-grid';
import 'react-datasheet-grid/dist/style.css';
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  gutterMainRoot: {
    display: 'flex !important',
    alignItems: 'flex-start !important',
    // padding: '10px',
    backgroundColor: '#f9f9f9 !important',
    borderBottom: '1px solid #ddd !important',
    height: '100% !important',
    boxSizing: 'border-box',
      width: '100% !important',
      padding: '0 !important'
  },
  sideA: {
    minWidth: '40px !important',
    fontWeight: 600,
    fontSize: '1.2em !important',
    color: '#005eff !important',
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    borderRight: '1px solid #ddd !important',
    marginRight: '10px !important',
    height: '100% !important'
  },
  sideB: {
    flex: 1,
    display: 'flex !important',
    flexDirection: 'column',
    gap: '2px !important', // Space between rows
    height: '100% !important',
    '&:hover' : {
      overflow: 'auto !important'
    }
  },
  labels: {
    color: '#333 !important',
    fontWeight: 600,
    fontSize: '0.9em !important',
  },
  data: {
    color: '#555 !important',
    fontWeight: 400,
    fontSize: '0.9em !important',
    wordBreak: 'break-word'
  },
}))

interface IDataGridProps<T> {
  data: T[];
  columns: Column<T>[];
  onChange?: (updatedData: T[]) => void;
  reportType: string
}

const DataGrid = <T,>({ data, columns, onChange, reportType }: IDataGridProps<T>) => {
  const classes = useStyles();
  const isDaily = ['daily'].includes(reportType);
  
  return (
    <DataSheetGrid
      value={data}
      onChange={onChange}
      columns={columns}
      // height={450}
      style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }}
      height={1085}
      rowHeight={120}
      lockRows
      headerRowHeight={35}
      gutterColumn={{
        basis: 400,
        component: ({ rowData, rowIndex }) => {
          return (
            <div className={classes.gutterMainRoot}>
            <div className={classes.sideA}>{rowIndex + 1}</div>
            <div className={classes.sideB}>
              <div>
                <span className={classes.labels}>Unit:</span>{' '}
                <span className={classes.data}>{rowData['unitTitle'] || '-'}</span>
              </div>
              <div>
                <span className={classes.labels}>Activity:</span>{' '}
                <span className={`${classes.data}`}>{rowData['activity'] || '-'}</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
              <div style={{ width: '50%' }}>
                <span className={classes.labels}>Block:</span>{' '}
                <span className={classes.data}>{rowData['blockName'] || '-'}</span>
              </div>
              <div style={{ width: '50%' }}>
                <span className={classes.labels}>Floor:</span>{' '}
                <span className={classes.data}>{rowData['floorLabel'] || '-'}</span>
              </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
              <div style={{ width: '50%' }}>
                <span className={classes.labels}>Planned start:</span>{' '}
                <span className={classes.data}>{rowData['plannedStart'] || '-'}</span>
              </div>
              <div style={{ width: '50%' }}>
                <span className={classes.labels}>Planned end:</span>{' '}
                <span className={classes.data}>{rowData['plannedEnd'] || '-'}</span>
              </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
              <div style={{ width: '50%' }}>
                <span className={classes.labels}>Total QTY:</span>{' '}
                <span className={classes.data}>
                  {rowData['plannedTotalQty'] || '-'}{' '}
                  {rowData['plannedTotalQty'] ? rowData['unitOfMeasure'] : ''}
                </span>
              </div>
              {isDaily ? <div style={{ width: '50%' }}>
                <span className={classes.labels}>Month's target:</span>{' '}
                <span className={classes.data}>
                  {rowData['monthsTarget'] || '-'}{' '}
                  {rowData['monthsTarget'] ? rowData['unitOfMeasure'] : ''}
                </span>
              </div> : null}
              </div>
            </div>
          </div>
          )
        },
        // title: () => <div style={{display: 'flex'}}>Unit Activity details</div>,
        title: 'Unit Activity details'
      }}
    />
  );
};

export default DataGrid;
