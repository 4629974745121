import React, { memo, useState, useEffect } from 'react';
import { useStore } from '../../../models/ProvideModel';
import { getDPRReport } from '../../../api/transactionServer';
import { IFile } from '../../../containers/ticketPopup/tabItems/attachments/Attachment';
import { ProgressReportFrequencies } from '../../../models/enums';
import { DatePickerInput } from '@mantine/dates';
import { IconCalendar, IconList } from '@tabler/icons-react';
import moment from 'moment';
import LoadingSpinner from '../../../components/loadingSkelaton/LoadingSpinner';
import { Drawer, Button } from '@mantine/core';
import NoDataFound from '../../fbtReports/NoDataFound';

function extractFileData(data: { [K: string]: any; }): IFile | null {
  const { fileId, fileName, version } = data;
  if (!fileId) { return null; }
  return {
    fileId,
    fileName,
    version,
    fileType: "application/pdf",
  } as IFile;
}


const DPRReport = memo(({ reportDate }: { reportDate: string; }) => {
  const [loading, setLoading] = useState(false);
  const [reportFile, setReportFile] = useState<IFile | null>(null);
  const store = useStore();
  useEffect(() => {
    if (store.projectInfo.currentProject) {
      setLoading(() => true);
      getDPRReport({ projectId: store.projectInfo.currentProject.id, targetPeriod: reportDate, frequency: ProgressReportFrequencies.DAY })
        .then(({ data }) => {
          setReportFile(() => (Array.isArray(data) && data.length) ? extractFileData(data[0]!) : data);
          setLoading(false);
        })
        .catch(() => { setLoading(false); });
    }
  }, [reportDate]);
  return loading ?
    (
      <LoadingSpinner />
    ) :
    reportFile ?
      (
        <div style={{ display: "flex", width: "100%", height: "100%" }}>
          <iframe src={`${process.env.REACT_APP_TRANSACTION_SERVER_URL}files/download/v3?customerName=${store.auth.customerId}&fileId=${reportFile.fileId}&versionId=${reportFile.version || 0}`}
            style={{ width: "100%", height: "100%" }}
          >
          </iframe>
        </div>
      )
      :
      (
        <div style={{ display: "flex", placeContent: "center", alignSelf: "center", width: "100%" }}>
          <NoDataFound title={`No reports found for the selected date (${moment(reportDate).format('DD-MMM-YYYY')}).`} />
        </div>
      );
});

const DPRReportsPage = memo(() => {
  const yesterday = moment().subtract(1, 'days').format("YYYY-MM-DD");
  const [reportDate, setReportDate] = useState<string | null>(yesterday);
  const [drawerOpened, setDrawerOpened] = useState(false);

   // Generate the days of the month
   const currentMonth = moment().month();
   const currentYear = moment().year();
   const daysInMonth = moment().daysInMonth();

   const daysList = Array.from({ length: daysInMonth }, (_, i) => {
    const date = moment(`${currentYear}-${currentMonth + 1}-${i + 1}`, "YYYY-MM-DD");
    return {
      day: i + 1,
      formatted: date.format("YYYY-MM-DD"),
    };
  });
 
  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', padding: '0.5em 1em' }}>
        <div>
          <h3 style={{ margin: 0 }}>Daily Progress Report {reportDate ? `[for: ${moment(reportDate).format('DD-MMM-YYYY')}]` : null}</h3>
        </div>
        <div style={{ display: "flex", alignItems: 'center', gap: '1em' }}>
          {/* Toggle Button for Drawer */}
          <Button
            variant="outline"
            color="blue"
            onClick={() => setDrawerOpened(true)}
            leftIcon={<IconList size={16} />}
          >
          {`${moment().format("MMMM")} ${currentYear}`}
          </Button>
          <span style={{ marginRight: '1em', fontWeight: 600 }}>DPR date: </span>
          <DatePickerInput
            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
            // label="Pick date"
            defaultValue={moment(yesterday, "YYYY-MM-DD").utc().toDate()}
            placeholder="Pick date"
            value={reportDate ? moment(reportDate).utc().toDate() : null}
            onChange={(val) => { setReportDate(() => val ? moment(val).format("YYYY-MM-DD") : val); }}
            // mx="auto"
            // maw={400}
            miw={200}
            valueFormat="DD-MMM-YYYY"
          />
        </div>
      </div>

      {/* DRAWER COMPONENT */}
      <Drawer
        opened={drawerOpened}
        onClose={() => setDrawerOpened(false)}
        title={`DPR for ${moment().format("MMMM")} ${currentYear}`}
        position="left"
        size="xs"
        // overlayOpacity={0.3}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {daysList.map(({ day, formatted }) => (
            <Button
              key={formatted}
              fullWidth
              variant={reportDate === formatted ? "filled" : "outline"}
              color={reportDate === formatted ? "blue" : "gray"}
              onClick={() => {
                setReportDate(formatted);
                setDrawerOpened(false); // Close drawer on selection
              }}
            >
              {moment(formatted).format("DD-MMM-YYYY")}
            </Button>
          ))}
        </div>
      </Drawer>

      <div style={{ display: "flex", width: "100%", flexGrow: 1 }}>
        {reportDate ? (
          <DPRReport reportDate={reportDate} />
        )
          : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'inherit', width: '100%' }}>
            <div style={{ display: 'flex', padding: '2em' }}>
              <p style={{ fontSize: '1.5em', color: '#585858' }}>Please choose a date above to view report.</p>
            </div>
          </div>}
      </div>
    </div>
  );
});
export default DPRReportsPage;
