import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from '../../../../components/DataSheetGrid';
import { textColumn, keyColumn, Column } from 'react-datasheet-grid';
import { getDate, parseISO, getDaysInMonth, format } from 'date-fns';
import { Button, Select } from '@mantine/core';
import useStyles from './DailyTargetsStyles';
import { commonFetch, fetchWBSActivities, upsertTargets, reCalculateTargets } from '../../../../api/transactionServer';
import { useStore } from '../../../../models/ProvideModel';
import { CommonApiEntities, DailyPlanningModes } from '../../../../models/enums';
import { Instance } from 'mobx-state-tree';
import { StoreModel } from '../../../../models/DataStore';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { IconRefresh, IconDeviceFloppy, IconTargetArrow } from '@tabler/icons-react';
import LoadingSpinner from '../../../../components/loadingSkelaton/LoadingSpinner';
import NoDataForTargets from '../noData/NoDataForTargets';

function getMonthDateRange(year, month) {
  const startDate = new Date(year, month - 1, 1).toISOString().slice(0, 10) + ' 23:59:59.999';
  const endDate = new Date(year, month).toISOString().slice(0, 10) + ' 23:59:59.999';
  return { startDate, endDate };
}

const transformWBSData = async ({ store, year, month }: { store: Instance<typeof StoreModel>; year: number; month: number; }) => {
  const dates = getMonthDateRange(year, month);
  let data: any[] = [];
  const { data: unitActivityData } = await commonFetch({
    projectId: store.projectInfo.currentProject?.id,
    entity: CommonApiEntities.UNIT_ACTIVITIES,
    filters: { isDPR: true, planStart: dates.endDate, planEnd: dates.startDate }
  }).catch((err) => { console.error(err); throw new Error() });

  if (unitActivityData?.length) {
    const { data: wbsDataForTheFrequency } = await fetchWBSActivities({
      projectId: store.projectInfo.currentProject?.id,
      unitActivityIds: unitActivityData.map((ua) => ua.id),
      frequency: 'DAY,MONTH',
      targetPeriod: `${year}-${month.toLocaleString("en-GB", { minimumIntegerDigits: 2 })}`
    }).catch((err) => { console.error(err); throw new Error() });

    const activityMap = {};

    unitActivityData.forEach((unitAct) => {
      const unitActivityId = unitAct.id;
      const existingWBS = wbsDataForTheFrequency?.filter((wbs) => wbs.unitActivityId === unitActivityId) || [];

      activityMap[unitActivityId] = {
        unitTitle: unitAct.unitTitle,
        unitActivity: unitAct.id,
        activity: unitAct.title,
        plannedStart: moment(unitAct.planStart).format('DD-MM-YYYY'),
        plannedEnd: moment(unitAct.planEnd).format('DD-MM-YYYY'),
        plannedTotalQty: unitAct.plannedTotalQuantity,
        unitOfMeasure: unitAct.unitOfMeasure,
        blockName: unitAct.blockName,
        floorLabel: unitAct.floorLabel,
        changedBy: 'SYSTEM'
      };

      existingWBS.forEach((wbs) => { //If an unit activity has more than one targets for the month
        if (wbs.frequency === 'DAY') {
          const day = getDate(parseISO(wbs.targetPeriod));
          const targetMonth = wbs.targetPeriod.split('-')[1]
          if (month === Number.parseInt(targetMonth)) {
            activityMap[unitActivityId][`day${day}`] = wbs.quantity  // (wbs.quantity && wbs.quantity >=0) ? Number.parseFloat(wbs.quantity).toFixed(2) : null;
          }
        }
        if (wbs.frequency === 'MONTH') {
          const targetMonth = wbs.targetPeriod.split('-')[1]
          if (month === Number.parseInt(targetMonth)) {
            activityMap[unitActivityId][`monthsTarget`] = wbs.quantity  // (wbs.quantity && wbs.quantity >=0) ? Number.parseFloat(wbs.quantity).toFixed(2) : null;
          }
        }
      });

      // const daysInMonth = getDaysInMonth(new Date(year, month - 1));
      // for (let day = 1; day <= daysInMonth; day++) {
      //   if (!activityMap[unitActivityId][`day${day}`]) {
      //     activityMap[unitActivityId][`day${day}`] = null;
      //   }
      // }
    });

    const sortedData = Object.values(activityMap).sort((a: any, b: any) => {
      // Sort by unitTitle
      const unitTitleComparison = a.unitTitle.localeCompare(b.unitTitle);
      if (unitTitleComparison !== 0) return unitTitleComparison;

      // Sort by activity
      const activityComparison = a.activity.localeCompare(b.activity);
      if (activityComparison !== 0) return activityComparison;

      // Sort by plannedStart (handle missing or invalid dates)
      const dateA = a.plannedStart ? new Date(a.plannedStart).getTime() : 0;
      const dateB = b.plannedStart ? new Date(b.plannedStart).getTime() : 0;
      return dateA - dateB;
    });
    // data = Object.values(activityMap);
    data = sortedData;
  }

  return data;
};

const DailyTargets = () => {
  const classes = useStyles();
  const store = useStore();
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1);
  const [data, setData] = useState<any[]>([]);
  const [changes, setChanges] = useState({});
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const currentYear = new Date().getFullYear();
  const dailyPlanningmode = store.planning.features['DPR']['metadata']['dailyPlanningMode'];
  const [calculationLoading, setCalculationLoading] = useState(false);
  const dayOffs = store.planning.features?.dayOffs

  const baseColumns: Column<any>[] = [
    {
      ...keyColumn('unitTitle', textColumn),
      title: 'Unit',
      minWidth: 200,
      disabled: true,
    },
    {
      ...keyColumn('activity', textColumn),
      title: 'Activity Name',
      minWidth: 250,
      disabled: true,
    },
    {
      ...keyColumn('plannedStart', textColumn),
      title: 'Planned start',
      minWidth: 120,
      disabled: true,
    },
    {
      ...keyColumn('plannedEnd', textColumn),
      title: 'Planned end',
      minWidth: 120,
      disabled: true,
    },
    {
      ...keyColumn('plannedTotalQty', textColumn),
      title: 'Total QTY',
      minWidth: 90,
      disabled: true,
    },
    {
      ...keyColumn('unitOfMeasure', textColumn),
      title: 'UoM',
      minWidth: 90,
      disabled: true,
    },
    {
      ...keyColumn('monthsTarget', textColumn),
      title: `Month's Target`,
      minWidth: 120,
      disabled: true,
    },
  ];

  const columns = useMemo(() => {
    const daysInMonth = getDaysInMonth(new Date(year, month - 1));
    const dayColumns: Column<any>[] = Array.from({ length: daysInMonth }, (_, index) => {
      const date = new Date(year, month - 1, index + 1);
      const dayOfWeek = date.toLocaleString('en-US', { weekday: 'long' }).toUpperCase(); // Convert day to uppercase
      const yearString = date.getFullYear().toString();
      const dateString = format(date, 'yyyy-MM-dd'); // Convert date to YYYY-MM-DD format
      const isWeekend = dayOffs?.weekOffDays.includes(dayOfWeek) || (dayOffs?.holidays[yearString] && dayOffs.holidays[yearString].includes(dateString));
      // const isWeekend = date.getDay() === 0
      return {
        ...keyColumn(`day${index + 1}`, textColumn),
        // title: `${index + 1}`,
        title: `${format(date, 'dd')} ${format(date, 'EEE')}`,
        cellClassName: ({ rowData, rowIndex }) => changes[rowIndex] && changes[rowIndex][`day${index + 1}`] ? `${classes.cellChanged}` : '',
        disabled: isWeekend || dailyPlanningmode === DailyPlanningModes.SYSTEM
      }
    });
    return [
      // ...baseColumns, 
      ...dayColumns];
  }, [year, month, changes]);


  const loadData = async () => {
    setLoading(true);
    setIsSaveButtonDisabled(true);
    const transformedData = await transformWBSData({ store, year, month });
    setData(transformedData);
    setChanges(transformedData);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [year, month]);

  const handleGridChange = (updatedData) => {
    setChanges((prevChanges) => {
      const newChanges = { ...prevChanges };
      updatedData.forEach((row, rowIndex) => {
        Object.keys(row).forEach((key) => {
          if (row[key] !== prevChanges[rowIndex]?.[key]) {
            // console.log('newChanges[rowIndex]',newChanges[rowIndex], row[key], !isNaN(row[key]))
            if (!newChanges[rowIndex]) newChanges[rowIndex] = {};
            newChanges[rowIndex][key] = (row[key] >=0 && !isNaN(row[key])) ? row[key] : null;
            newChanges[rowIndex]['changedBy'] = 'USER';
          }
        });
      });
      setIsSaveButtonDisabled(false);  // Enable Save button on any change
      return newChanges;
    });
  };

  const handleSaveChanges = async () => {
    const scheduleList: any = [];

    Object.entries(changes).forEach(([rowIndex, rowData]) => {
      const originalData = data[rowIndex];
      const unitActivityId = originalData.unitActivity;

      Object.entries(rowData as Record<string, any>).forEach(([key, value]) => {
        if (key.startsWith('day') && value && value >= 0 && rowData && rowData['changedBy'] === 'USER') {
          const day = key.replace('day', '').padStart(2, '0');
          const targetPeriod = `${year}-${month.toString().padStart(2, '0')}-${day}`;

          scheduleList.push({
            unitActivityId: unitActivityId,
            frequency: 'DAY',
            targetPeriod: targetPeriod,
            quantity:  Number.parseFloat(value).toFixed(2),
          });
        }
      });
    });

    try {

      if(!scheduleList.length) {
        // setIsSaveButtonDisabled(true);
        await loadData();
        return;
      }

      await upsertTargets({ projectId: store.projectInfo.currentProject?.id, scheduleList })
      setIsSaveButtonDisabled(true); // Disable Save button after successful save
      await loadData(); // Reload the data to show updated values
    } catch (error) {
      console.error('Failed to save changes:', error);
      throw error;
    }
  };

  const handleReCalculate = async () => {
    const projectId = store.projectInfo.currentProject?.id || '';
    const targetPeriod = `${year}-${month.toString().padStart(2, '0')}`;
    setCalculationLoading(true);
    try {
      await reCalculateTargets({ projectId, targetPeriod });
      loadData();
      setCalculationLoading(false);
    } catch(error) {
      console.log('handleReCalculate error',error)
      setCalculationLoading(false);
    }
  }

  const monthOptions = Array.from({ length: 12 }, (_, i) => ({
    value: `${i + 1}`,
    label: new Date(0, i).toLocaleString('en', { month: 'long' }),
  }));

  const yearOptions = Array.from({ length: 3 }, (_, i) => {
    const year = currentYear - 1 + i;
    return {
      value: `${year}`,
      label: `${year}`,
    };
  });

  // console.log('changes',changes);

  return (
    <div className={classes.root}>
      <h3 className={classes.title}>Targets by Day</h3>

      <div className={classes.dropdownDiv}>
        {/* Dropdowns on the left */}
        <div className={classes.dropdownsContainer}>
          <Select
            label="Choose Year"
            value={String(year)}
            onChange={(value) => setYear(Number(value))}
            data={yearOptions}
            placeholder="Select year"
            styles={{ dropdown: { maxWidth: 200 } }}
            className={classes.dropdown}
            sx={{
              ["& .mantine-Select-input"]: { borderRadius: 0 }
            }}
          />
          <Select
            label="Choose Month"
            value={String(month)}
            onChange={(value) => setMonth(Number(value))}
            data={monthOptions}
            placeholder="Select month"
            styles={{ dropdown: { maxWidth: 200 } }}
            className={classes.dropdown}
            sx={{
              ["& .mantine-Select-input"]: { borderRadius: 0 }
            }}
          />
        </div>

        {/* Buttons on the right */}
        <div className={classes.buttonsContainer}>
          {
            (dailyPlanningmode !== DailyPlanningModes.SYSTEM) ? 
            <Button
            onClick={handleSaveChanges}
            className={classes.saveButton}
            disabled={isSaveButtonDisabled}
          >
            Save <IconDeviceFloppy width={'1em'} height={'1em'} style={{ marginLeft: '0.25em' }} />
          </Button>
          : null
          }
          <Button
            onClick={loadData}
            className={classes.saveButton}
          >
            Refresh <IconRefresh width={'1em'} height={'1em'} style={{ marginLeft: '0.25em' }} />
          </Button>
          {
            (dailyPlanningmode === DailyPlanningModes.PLANNER) ? 
            <Button
            onClick={handleReCalculate}
            className={classes.saveButton}
            disabled={calculationLoading}
          >
            Re-Calculate Targets <IconTargetArrow width={'1em'} height={'1em'} style={{ marginLeft: '0.25em' }} />
          </Button>
          : null
          }
        </div>
      </div>

      {/* <DataGrid key={`${year}-${month}`} data={data} columns={columns} onChange={handleGridChange} /> */}
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <LoadingSpinner />
        </div>
      ) : !data.length ? (
        <NoDataForTargets />
      ) : (
        <div style={{ height: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
          <DataGrid key={`${year}-${month}`} data={data} columns={columns} onChange={handleGridChange} reportType={'daily'} />
        </div>
      )}
    </div>
  );
};

export default observer(DailyTargets);
